import axios from "axios";
// import { isDev } from "../../pages/lib";

export const isDev = () =>   !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

export const uri = isDev()? 'https://v1.amanhmo.com/' : 'https://v1.amanhmo.com/';
export const uri_img = 'https://v1.amanhmo.com/';
export const uri2 = 'https://v1.amanhmo.com/';

const header =  {
    'Content-Type': 'application/json',
  'Cache-Control': 'no-cache, no-store, must-revalidate',
  'Pragma': 'no-cache',
  'Expires': 0,
  'Version': '1.5.1',
  'Application': 1,
  'Dev':  isDev()? 1 : 0
}

const Axios = axios.create({baseURL: uri, headers: header });

export default Axios;
